import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import IconOne from '../assets/images/icon_1.png';
import IconTwo from '../assets/images/icon_2.png';
import IconThree from '../assets/images/icon_3.png';
import IconFour from '../assets/images/icon_4.png';
import IconFive from '../assets/images/icon_5.png';
import IconSix from '../assets/images/icon_6.png';
import officework from '../assets/images/officework.png';
import Hero from '../components/Hero';
import Button from '../components/PageComponents/Button';
import OverlapSection from '../components/PageComponents/OverlapSection';
import { Subgrid, TwoColumnGrid } from '../styles/Grids';
import PageStyles from '../styles/PageStyles';

gsap.registerPlugin(ScrollTrigger);

const ColumnGrid = styled.section`
  display: grid;
  row-gap: var(--row-gap);
`;

const FullWidthHeader = styled.div`
  background-image: url(${officework});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  h3 {
    padding-top: var(--margin);
    padding-bottom: 10vw;
    padding-left: calc(var(--margin) * 2);
  }
`;

const QuestionMarkStyles = styled.p`
  font-weight: 900;
  font-size: clamp(25rem, 40vw, 70rem);
  text-align: center;
  line-height: 1.2;
`;

export default function IndexPage() {
  const ref = useRef(null);

  useEffect(() => {
    gsap.from(ref.current, {
      scrollTrigger: '.start-animation', // start the animation when ".box" enters the viewport (once)
      x: 500,
      stagger: 0.4,
    });
  }, []);

  return (
    <PageStyles>
      <Helmet
        htmlAttributes={{
          lang: 'fi',
        }}
      >
        <title>
          Yhdistä strateginen suunnittelu ja käytännön digimarkkinointi - Digis
        </title>
        <meta
          name="description"
          content="Digimarkkinointia strateginen markkinointi ja pitkä markkinointikokemus edellä.
Käynnistä digistrategia ELY-yritystuen avulla. Ota yhteyttä. Tilaa maksuton opas."
        />
      </Helmet>
      <Hero className="">
        <span>KOKEMUS</span>
        <br /> RATKAISEE.
      </Hero>
      <TwoColumnGrid>
        <Subgrid>
          <span className="bar" />
          <div className="question-mark">
            <QuestionMarkStyles>?</QuestionMarkStyles>
          </div>
        </Subgrid>
        <div className="start-animation" ref={ref}>
          <h2 className="content" ref={ref}>
            <span className="cursive">Mikä tekee</span>{' '}
            <span className="bold"> MEISTÄ</span>
            <br /> poikkeuksellisen
            <br />
            <span className="cursive">digitoimiston?</span>
          </h2>
          <p className="content" ref={ref}>
            Digitaalisen markkinoinnin suunnittelu ja toteutus vaativat
            markkinoinnin osaamista. Kun myynti sakkaa, nopeita ja helppoja
            ratkaisuja ei ole. Ongelmat ovat syvemmällä kuin verkkosivujen
            kävijämäärissä tai sähköpostimainoksissa.
          </p>

          <p className="content">
            Kun ongelmat jatkuvat vuosi toisensa jälkeen, matkalla ehtii
            tapahtua paljon. Sellaista, johon olisi pitänyt tarttua aikaisemmin
            heti ongelmien ilmaantuessa. Seurauksena on huono kannattavuus,
            myynnin lasku, asiakaskato ja sitä kautta likvideettiongelmat, jotka
            edelleen lisäävät markkinoinnin kustannuspaineita.
          </p>

          <p>
            Digis on sitoutunut ja kustannustehokas kumppani yritykselle, joka
            etsii selkokielistä apua myynnin ja markkinoinnin ongelmiin.
          </p>

          <p>
            Pitkä markkinointikokemus kymmeniltä eri toimialoilta luo perustan
            asiakasymmärrykseen ja kykyyn ratkaista kasvun esteenä olevat
            ongelmia digitaalisesti. Pitkä kokemus yrittäjyydestä pitää jalat
            tukevasti maassa.
          </p>

          <p>
            Lähtökohtana suunnittelussa ja kaikessa tekemisessä on
            kustannustietoisuus. Muutaman suunnittelijan sijaan yritys saa
            käyttöönsä laajan verkoston markkinoinnin, palvelumuotoilun,
            sisällön, verkkokaupan, digimarkkinoinnin ja analytiikan
            ammattilaisia.
          </p>
          <h2>
            <span className="cursive">Kasvua ja kannattavuutta</span> <br />
            <span className="bold">ELY</span>-kehittämispalveluilla.{' '}
          </h2>
          <p>
            Digitaalinen Markkinointi Digis on
            <span className="bold">
              <Link className="link" to="/ota-yhteytta">
                {' '}
                ELY-kehittämispalveluiden
              </Link>{' '}
            </span>
            hyväksymä{' '}
            <a
              className="bold link"
              href="https://sahkoinenasiointi.ahtp.fi/fi/asiantuntijahaku?hakusana=Merja%20Saari"
              target="_blank"
              rel="noreferrer"
            >
              palveluntuottaja ja vastuuasiantuntija.
            </a>{' '}
            <a
              className="bold link"
              href="https://www.ely-keskus.fi/web/yritystenkehittamispalvelut/markkinointi-ja-asiakkuudet"
              target="_blank"
              rel="noreferrer"
            >
              ELY-keskuksen yritysten kehittämispalvelut
            </a>{' '}
            on pk-yrityksille suunnattu taloudellinen tuki, jonka avulla yritys
            voi kartoittaa markkinoinnin kehittämistarpeet ja käynnistää esim.
            markkinoinnin digitalisaation. Autamme yrityksiä palauttamaan
            liiketoiminnan kasvun ja kannattavuuden.
          </p>
          <Button>
            <Link to="/ely-kehittamispalvelut">Lue lisää</Link>
          </Button>
        </div>
      </TwoColumnGrid>
      <ColumnGrid>
        <FullWidthHeader ref={ref}>
          <h3 className="logo">
            DIGIS<span className="cursive">-palvelut ...</span>
          </h3>
        </FullWidthHeader>

        <OverlapSection>
          <div className="title-section">
            <h2>
              <span className="cursive">Olemme</span>
              <br />{' '}
              <span style={{ paddingLeft: '6rem' }}>
                ylpeitä <span className="cursive">häikäisevästä</span>{' '}
                digimarkkinointitiimistämme.
              </span>
            </h2>
          </div>
          <div className="overlap" />
          <div className="content-section">
            <div>
              <img src={IconOne} alt="avatar" />
              <p>
                Digitaalinen verkkoliiketoiminta,
                <br /> myynnin ja markkinoinnin integrointi, <br /> projektin ja
                tiimin vetäjä, <br /> ELY-vastuuasiantuntija
              </p>
            </div>
            <div>
              <img src={IconTwo} alt="avatar" />
              <p>
                Toimiala-asiantuntija,
                <br />
                markkinoinnin
                <br /> strateginen suunnittelu,
                <br />
                swot- ja tilanneanalyysi
              </p>
            </div>
            <div>
              <img src={IconThree} alt="avatar" />
              <p>
                Some-markkinointi, <br /> sosiaalisen median <br /> kanavat
              </p>
            </div>
            <div>
              <img src={IconFour} alt="avatar" />
              <p>
                Graafinen suunnittelija, <br /> Art director, <br /> brändin ja
                graafisen
                <br />
                ilmeen kehittäminen
              </p>
            </div>
            <div>
              <img src={IconFive} alt="avatar" />
              <p>
                Webkehittäjä ja ohjelmoija,
                <br /> verkkoratkaisujen <br />
                toimivuus, käytettävyys ja <br />
                käyttäjäystävällisyys
              </p>
            </div>
            <div>
              <img src={IconSix} alt="avatar" />
              <p>
                SEO-optimoija ja
                <br /> sisällöntuottaja, copywriter,
                <br /> inbound-viestinnän
                <br /> ammattilainen
              </p>
            </div>
          </div>
        </OverlapSection>
      </ColumnGrid>
    </PageStyles>
  );
}
